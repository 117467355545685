import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/ordenes",
      name: "home",
      meta: {
        requiresAuth: true,
      },
      //component: () => import("@/views/theme/Base"),
      component: () => import("@/views/oms/layouts/Base.vue"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue"),
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/views/pages/Builder.vue"),
        },
        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/views/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/views/pages/vue-bootstrap/Alert.vue"),
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/views/pages/vue-bootstrap/Badge.vue"),
            },
            {
              path: "breadcrumb",
              name: "vue-bootstrap-breadcrumb",
              component: () =>
                import("@/views/pages/vue-bootstrap/Breadcrumb.vue"),
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/views/pages/vue-bootstrap/Button.vue"),
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/ButtonGroup.vue"),
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/views/pages/vue-bootstrap/ButtonToolbar.vue"),
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/views/pages/vue-bootstrap/Card.vue"),
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () =>
                import("@/views/pages/vue-bootstrap/Carousel.vue"),
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () =>
                import("@/views/pages/vue-bootstrap/Collapse.vue"),
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () =>
                import("@/views/pages/vue-bootstrap/Dropdown.vue"),
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/views/pages/vue-bootstrap/Embed.vue"),
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/views/pages/vue-bootstrap/Form.vue"),
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormCheckbox.vue"),
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormFile.vue"),
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormGroup.vue"),
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormInput.vue"),
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormRadio.vue"),
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormSelect.vue"),
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormTextarea.vue"),
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/views/pages/vue-bootstrap/Image.vue"),
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/InputGroup.vue"),
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/views/pages/vue-bootstrap/Jumbotron.vue"),
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/views/pages/vue-bootstrap/LayoutGridSystem.vue"),
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/views/pages/vue-bootstrap/Link.vue"),
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/ListGroup.vue"),
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/views/pages/vue-bootstrap/Media.vue"),
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/views/pages/vue-bootstrap/Modal.vue"),
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/views/pages/vue-bootstrap/Nav.vue"),
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/views/pages/vue-bootstrap/Navbar.vue"),
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/views/pages/vue-bootstrap/Pagination.vue"),
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/views/pages/vue-bootstrap/PaginationNav.vue"),
            },
            {
              path: "popover",
              name: "vue-bootstrap-popover",
              component: () =>
                import("@/views/pages/vue-bootstrap/Popover.vue"),
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () =>
                import("@/views/pages/vue-bootstrap/Progress.vue"),
            },
            {
              path: "spinner",
              name: "vue-bootstrap-spinner",
              component: () =>
                import("@/views/pages/vue-bootstrap/Spinner.vue"),
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/views/pages/vue-bootstrap/Table.vue"),
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/views/pages/vue-bootstrap/Tabs.vue"),
            },
            {
              path: "toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/views/pages/vue-bootstrap/Toasts.vue"),
            },
            {
              path: "tooltip",
              name: "vue-bootstrap-tooltip",
              component: () =>
                import("@/views/pages/vue-bootstrap/Tooltip.vue"),
            },
          ],
        },
        {
          path: "/vuetify",
          name: "vuetify",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/views/pages/vuetify/Alerts.vue"),
            },
            {
              path: "avatars",
              name: "vuetify-avatars",
              component: () => import("@/views/pages/vuetify/Avatars.vue"),
            },
            {
              path: "badges",
              name: "vuetify-badges",
              component: () => import("@/views/pages/vuetify/Badges.vue"),
            },
            {
              path: "buttons",
              name: "vuetify-buttons",
              component: () => import("@/views/pages/vuetify/Buttons.vue"),
            },
            {
              path: "calendars",
              name: "vuetify-calendars",
              component: () => import("@/views/pages/vuetify/Calendars.vue"),
            },
            {
              path: "cards",
              name: "vuetify-cards",
              component: () => import("@/views/pages/vuetify/Cards.vue"),
            },
            {
              path: "chips",
              name: "vuetify-chips",
              component: () => import("@/views/pages/vuetify/Chips.vue"),
            },
            {
              path: "dialog",
              name: "vuetify-dialog",
              component: () => import("@/views/pages/vuetify/Dialog.vue"),
            },
            {
              path: "autocompletes",
              name: "vuetify-autocompletes",
              component: () =>
                import("@/views/pages/vuetify/forms/Autocompletes.vue"),
            },
            {
              path: "file-inputs",
              name: "vuetify-file-inputs",
              component: () =>
                import("@/views/pages/vuetify/forms/FileInputs.vue"),
            },
            {
              path: "forms",
              name: "vuetify-forms",
              component: () => import("@/views/pages/vuetify/forms/Forms.vue"),
            },
            {
              path: "selection-controls",
              name: "vuetify-selection-controls",
              component: () =>
                import("@/views/pages/vuetify/forms/SelectionControls.vue"),
            },
            {
              path: "selects",
              name: "vuetify-selects",
              component: () =>
                import("@/views/pages/vuetify/forms/Selects.vue"),
            },
            {
              path: "textareas",
              name: "vuetify-textareas",
              component: () =>
                import("@/views/pages/vuetify/forms/Textareas.vue"),
            },
            {
              path: "text-fields",
              name: "vuetify-text-fields",
              component: () =>
                import("@/views/pages/vuetify/forms/TextFields.vue"),
            },
            {
              path: "simple-tables",
              name: "vuetify-simple-tables",
              component: () =>
                import("@/views/pages/vuetify/tables/SimpleTables.vue"),
            },
            {
              path: "data-tables",
              name: "vuetify-data-tables",
              component: () =>
                import("@/views/pages/vuetify/tables/DataTables.vue"),
            },
            {
              path: "tabs",
              name: "vuetify-tabs",
              component: () => import("@/views/pages/vuetify/Tabs.vue"),
            },
            {
              path: "timelines",
              name: "vuetify-timelines",
              component: () => import("@/views/pages/vuetify/Timelines.vue"),
            },
            {
              path: "tooltips",
              name: "vuetify-tooltips",
              component: () => import("@/views/pages/vuetify/Tooltips.vue"),
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/views/pages/vuetify/Treeview.vue"),
            },
          ],
        },
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/views/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/views/pages/wizard/Wizard-1.vue"),
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/views/pages/wizard/Wizard-2.vue"),
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/views/pages/wizard/Wizard-3.vue"),
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/views/pages/wizard/Wizard-4.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/views/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/views/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/views/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/views/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/views/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      name: "Public Tracking Order",
      path: "/tracking/:hash",
      component: () => import("@/views/oms/Ordenes/PublicTrackingOrder"),
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/oms/auth/Login2"),
    },
    {
      path: "/",
      component: () => import("@/views/oms/auth/Layout"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/oms/auth/Login"),
          meta: { name: "Login" },
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register"),
        },
      ],
    },
    {
      path: "/admin",
      meta: {
        requiresAuth: true,
      },
      component: () => import("@/views/oms/layouts/Base2.vue"),
      children: [
        {
          path: "usuarios",
          name: "admin usuarios",
          component: () => import("@/views/oms/Admin/DashboardUsuarios.vue"),
        },
      ],
    },
    {
      path: "/",
      meta: {
        requiresAuth: true,
      },
      component: () => import("@/views/oms/layouts/Base2.vue"),
      children: [
        {
          path: "ordenes",
          name: "ordenes",
          component: () => import("@/views/oms/Ordenes/Ordenes.vue"),
        },
        {
          path: "ordenes-storegroup",
          name: "ordenes-storegroup",
          component: () => import("@/views/oms/Ordenes/OrdenesStoreGroup.vue"),
        },
        {
          path: "ordenes-archivadas",
          name: "ordenes archivadas",
          component: () => import("@/views/oms/Ordenes/Archivados.vue"),
        },
        {
          path: "ordenes/importacion_masiva",
          name: "importacion masiva ordenes",
          component: () =>
            import("@/views/oms/Ordenes/ImportacionMasivaOrdenes.vue"),
        },
        {
          path: "ordenes/crear",
          name: "crear orden",
          component: () => import("@/views/oms/Ordenes/CrearOrden.vue"),
        },
        {
          path: "ordenes/nueva",
          name: "nueva orden",
          component: () => import("@/views/oms/Ordenes/NuevaOrden.vue"),
        },
        {
          path: "ordenes/:orden",
          name: "orden",
          component: () => import("@/views/oms/Ordenes/OrdenView.vue"),
        },
        {
          path: "ordenes-archivadas/:orden",
          name: "orden archivada",
          component: () => import("@/views/oms/Ordenes/OrdenArchivedView.vue"),
        },
        {
          path: "ordenes/ref/:ref",
          name: "orden por ref",
          component: () => import("@/views/oms/Ordenes/OrdenViewByRef.vue"),
        },
        {
          path: "envios",
          name: "envios",
          component: () => import("@/views/oms/Envios/Envios.vue"),
        },
        {
          path: "envios-storegroup",
          name: "envios-storegroup",
          component: () => import("@/views/oms/Envios/EnviosStoreGroup.vue"),
        },
        {
          path: "envios/:envio",
          name: "envio",
          component: () => import("@/views/oms/Envios/EnvioView.vue"),
        },
        {
          path: "envio-configuraciones",
          name: "envio-configuraciones",
          component: () => import("@/views/oms/Envios/EnvioConfiguracion.vue"),
        },
        {
          path: "envios-generar-etiqueta",
          name: "generar etiqueta",
          component: () => import("@/views/oms/Envios/GenerarEtiqueta.vue"),
        },
        {
          path: "envios-cargar-etiqueta",
          name: "cargar etiqueta(s)",
          component: () => import("@/views/oms/Envios/CargarEtiqueta.vue"),
        },
        {
          path: "manifiestos",
          name: "Manifiestos",
          component: () => import("@/views/oms/Envios/Manifests.vue"),
        },
        {
          path: "envios-cargar-manifiestos",
          name: "cargar manifiesto(s)",
          component: () => import("@/views/oms/Envios/CargarManifiestos.vue"),
        },
        {
          path: "facturaciones",
          name: "facturaciones",
          component: () =>
            import("@/views/oms/Facturaciones/Facturaciones.vue"),
        },
        {
          path: "envios-generar-dte",
          name: "generar dte",
          component: () => import("@/views/oms/Facturaciones/GenerarDte.vue"),
        },
        {
          path: "envios-cargar-dte",
          name: "cargar dte(s)",
          component: () => import("@/views/oms/Envios/CargarDte.vue"),
        },
        {
          path: "agrupar",
          name: "agrupar",
          component: () => import("@/views/oms/Preparacion/Agrupar.vue"),
        },
        {
          path: "picking",
          name: "picking",
          component: () => import("@/views/oms/Preparacion/Picking.vue"),
        },
        {
          path: "compras/ordenes",
          name: "ordenes de compra",
          component: () => import("@/views/oms/Compras/OrdenesDeCompra2.vue"),
        },
        {
          path: "compras/envasar",
          name: "envasar",
          component: () => import("@/views/oms/Compras/EnvasarOC.vue"),
        },
        {
          path: "compras/ordenes/crear",
          name: "crear orden de compra",
          component: () => import("@/views/oms/Compras/CrearOrdenDeCompra.vue"),
        },
        {
          path: "admin/proveedores",
          name: "proveedores",
          component: () => import("@/views/oms/Compras/Proveedores.vue"),
        },
        {
          path: "ocs",
          name: "ocs",
          component: () => import("@/views/oms/Compras/OCs.vue"),
        },
        {
          path: "oc/:oc_ref",
          name: "orden de compra",
          component: () => import("@/views/oms/Compras/OrdenView.vue"),
        },
        {
          path: "productos/envasados",
          name: "productos envasados sin picking",
          component: () =>
            import("@/views/oms/Compras/ProductosEnvasadosNPConsolidado.vue"),
        },
        {
          path: "productos/envasados/:generic_sku",
          name: "productos envasados sin picking by name",
          component: () =>
            import("@/views/oms/Compras/ProductosEnvasadosNPByName.vue"),
        },
        {
          path: "inventario/warehouse",
          name: "Listado de bodega",
          component: () => import("@/views/oms/Inventario/Warehouse.vue"),
        },
        {
          path: "inventario/warehouse/:id",
          name: "Detalle bodega",
          props: true,
          component: () => import("@/views/oms/Inventario/WarehouseDetail.vue"),
        },
        {
          path: "inventario/stock/productos",
          name: "Stock Poductos",
          component: () => import("@/views/oms/Inventario/Stock.vue"),
        },
        {
          path: "inventario/stock/importacion_masiva",
          name: "importacion masiva stock",
          component: () =>
            import("@/views/oms/Inventario/ImportacionMasivaStock.vue"),
        },
        {
          path: "inventario/stock/productos/:sku",
          name: "Detalle Producto",
          props: true,
          component: () =>
            import("@/views/oms/Inventario/StockProductDetail.vue"),
        },
        {
          path: "inventario/stock/productos/:sku/:warehouse_id",
          name: "Producto-Bodegas",
          props: true,
          component: () =>
            import("@/views/oms/Inventario/StockProductWarehouseDetail.vue"),
        },
        {
          path: "productos/crear",
          name: "crear producto",
          component: () => import("@/views/oms/Productos/CrearProducto.vue"),
        },
        {
          path: "productos",
          name: "lista productos",
          component: () => import("@/views/oms/Productos/ListarProductos.vue"),
        },
        {
          path: "inventario/productos",
          name: "Productos Inventario",
          props: true,
          component: () => import("@/views/oms/Inventario/Product.vue"),
        },
        {
          path: "inventario/productos/:id",
          name: "Productos Detalle Inventario",
          props: true,
          component: () => import("@/views/oms/Inventario/ProductDetail.vue"),
        },
        {
          path: "inventario/locations",
          name: "location",
          props: true,
          component: () => import("@/views/oms/Inventario/Location.vue"),
        },
        {
          path: "inventario/locations/:id",
          name: "location-details",
          props: true,
          component: () => import("@/views/oms/Inventario/LocationDetail.vue"),
        },
        {
          path: "inventario/reasons",
          name: "reasons",
          props: true,
          component: () => import("@/views/oms/Inventario/Reason.vue"),
        },
        {
          path: "inventario/reasons/:id",
          name: "reasons-details",
          props: true,
          component: () => import("@/views/oms/Inventario/ReasonDetail.vue"),
        },
        {
          path: "inventario/categories",
          name: "category",
          props: true,
          component: () => import("@/views/oms/Inventario/Category.vue"),
        },
        {
          path: "inventario/categories/:id",
          name: "category-details",
          props: true,
          component: () => import("@/views/oms/Inventario/CategoryDetail.vue"),
        },
        {
          path: "inventario/product-bulk-load",
          name: "product-bulk-load",
          props: true,
          component: () => import("@/views/oms/Inventario/ProductBulkLoad.vue"),
        },
        {
          path: "movement",
          name: "movement",
          props: true,
          component: () => import("@/views/oms/Inventario/Movement.vue"),
        },
        {
          path: "movement/movement-detail/:id",
          name: "movement-details",
          props: true,
          component: () => import("@/views/oms/Inventario/MovementDetail.vue"),
        },
        {
          path: "duallistorders",
          name: "duallistorders",
          props: true,
          component: () => import("@/views/oms/Inventario/DualListOrders.vue"),
        },
        {
          path: "inventario/marketplaces",
          name: "Marketplace",
          component: () => import("@/views/oms/Inventario/SalesChannel.vue"),
        },
        {
          path: "inventario/saleschannel/saleschannel-detail/:id",
          name: "market-productlist",
          props: true,
          component: () =>
            import("@/views/oms/Inventario/SalesChannel-Details.vue"),
        },
        {
          path: "configuraciones",
          name: "configuracion",
          props: true,
          component: () =>
            import("@/views/oms/Configuraciones/Configuraciones.vue"),
        },
        {
          path: "configuraciones/marketplaces",
          name: "configuracion marketplaces",
          props: true,
          component: () =>
            import("@/views/oms/Configuraciones/Marketplace.vue"),
        },
        {
          path: "configuraciones/client-onboarding",
          name: "configuracion client onboarding",
          props: true,
          component: () =>
            import("@/views/oms/Configuraciones/ClientOnboarding.vue"),
        },
        {
          path: "configuraciones/endpoints-habilitados",
          name: "endpoints habilitados",
          props: true,
          component: () =>
            import("@/views/oms/Configuraciones/EndpointsHabilitados.vue"),
        },
        {
          path: "configuraciones/exportacion-ordenes",
          name: "exportacion ordenes",
          props: true,
          component: () =>
            import("@/views/oms/Configuraciones/ExportacionOrdenes.vue"),
        },
        {
          path: "dashboard-beta",
          name: "dashboard-beta",
          component: () => import("@/views/oms/Admin/DashboardBeta.vue"),
        },
        {
          path: "my-tokens",
          name: "my tokens",
          props: true,
          component: () => import("@/views/oms/Configuraciones/MyTokens.vue"),
        },
        {
          path: "dashboard-bi",
          name: "dashboard bi",
          props: true,
          component: () => import("@/views/oms/Admin/DashboardBi.vue"),
        },
        {
          path: "dashboard-bi-v2",
          name: "dashboard bi v2",
          props: true,
          component: () => import("@/views/oms/Admin/DashboardBiV2.vue"),
        },
        {
          path: "dashboard-status",
          name: "dashboard status",
          props: true,
          component: () => import("@/views/oms/Admin/DashboardStatus.vue"),
        },
        {
          path: "picking-orders",
          name: "picking-orders",
          props: true,
          component: () => import("@/views/oms/Picking/PickingOrders.vue"),
        },
        {
          path: "admin/store-groups",
          name: "store-groups",
          props: true,
          component: () => import("@/views/oms/Admin/StoreGroups.vue"),
        },
        {
          path: "admin/stores",
          name: "stores",
          props: true,
          component: () => import("@/views/oms/Admin/Stores.vue"),
        },
      ],
    },

    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue"),
    },
  ],
});

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
//import MockService from "./common/mock/mock.service";
//import { VERIFY_AUTH } from "./store/auth.module";
import VueIntercom from '@mathieustan/vue-intercom';
import VueGtm from '@gtm-support/vue2-gtm';
Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

// Ensure we checked auth before each page load.
/*
router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
*/
import moment from 'moment-timezone'
moment.locale('es');
if(localStorage.getItem('timezone')) moment.tz.setDefault(localStorage.getItem('timezone'));
Object.defineProperty(Vue.prototype, '$moment', {value: moment});
//vue List Picker
//import VueListPicker from 'vue-list-picker'
//axios
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
//Vue.use(VueListPicker)
Vue.axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  store.commit('passport/incrementLoadingStack');
  return config;
}, function (error) {
  store.commit('decrementLoadingStack');
  // Do something with request error
  return Promise.reject(error);
});
Vue.axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  store.commit('passport/decrementLoadingStack');
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  store.commit('passport/decrementLoadingStack');
  return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['passport/isTokenSet']) {
      next({
        name: 'login',
      });
    }else{
      Vue.axios.defaults.headers.common['Authorization'] = store.state.passport.token_type+' '+store.state.passport.access_token;
      if(!store.getters['passport/isTokenExpires']){
        store.dispatch('passport/fetchUser').then( response => {
          next();
        }).catch( error => {
          console.log(error);
          next({
            name: 'login',
          });
        });
      }else{
        store.dispatch('passport/refreshLogin')
        .then( response => {
          console.log(response);
          store.dispatch('passport/fetchUser').then( response => {
            next();
          }).catch( error => {
            console.log(error);
            next({
              name: 'login',
            });
          });
        }).catch( error => {
          console.log(error);
          next({
            name: 'login',
          });
        })
      }
    }
  } else {
    next() // make sure to always call next()!
  }
});

import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
Vue.use(DatetimePicker)


import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)
// import component and stylesheet
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

// see docs for available options
const datepickerOptions = {
  days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
  daysShort: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  texts: {
    apply: 'Aceptar',
    cancel: 'Cancelar',
    keyboardShortcuts: 'Atajos de teclado',
  },
}
import VueHotkey from 'v-hotkey'
Vue.use(VueHotkey)
// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions)
// Intercom
Vue.use(VueIntercom, { appId: 'fm065td5' });

if(process.env.NODE_ENV != 'development'){
  Vue.use(VueGtm, {
    id: 'GTM-N293RZS',
    defer: false,
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
}
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");



